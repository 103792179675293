/*
 * Copyright 2024 LLC Campus.
 */

import React, { useEffect, useState } from 'react'
import { fetchConfirmationToken, fetchCourseCatalog } from '../services/api'
import { toast } from 'react-toastify'
import { openYooKassaWidget } from '../yookassa'
import { useLocation } from 'react-router-dom'
import ItemGrid from '../components/ItemGrid'
import EmailInputContainer from '../components/EmailInputContainer'

function CourseCatalog () {
  const location = useLocation()
  const courseId = new URLSearchParams(location.search).get('courseId')
  const [tariffs, setCourseTariffs] = useState([])
  const [email, setEmail] = useState('')
  const [selectedTarifId, setSelectedTarifId] = useState(null)
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [hasErrorToast, setHasErrorToast] = useState(false)

  useEffect(() => {
    let isMounted = true
    localStorage.clear()
    if (!courseId) {
      if (isMounted) {
        const errorMessage = 'Ошибка: courseId отсутствует. Страница не может быть загружена.'
        toast.error(errorMessage)
        setHasErrorToast(true)
      }
      return;
    }

    const fetchData = async () => {
      try {
        const data = await fetchCourseCatalog(courseId)
        if (isMounted) {
          setCourseTariffs(data.items)
        }
      } catch (error) {
        if (isMounted) {
          if (!hasErrorToast) {
            toast.error(error.message)
            setHasErrorToast(true)
          }
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    };
  }, [courseId, hasErrorToast])

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    setIsEmailValid(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value))
  }

  const handleCardClick = (tarifId) => {
    setSelectedTarifId(tarifId)
  }

  const handlePurchaseClick = async () => {
    const selectedTarif = tariffs.find(tarif => tarif._id === selectedTarifId)

    if (selectedTarif) {
      try {
        const data = await fetchConfirmationToken(selectedTarifId, email)

        if (data.confirmationToken && data.transactionId) {
          localStorage.setItem('purchaseData', JSON.stringify({
            returnPath: `/course?courseId=${courseId}`,
            activationLink: `campus://courses/activate?courseId=${courseId}&code=`
          }))
          setIsWidgetOpen(true);
          openYooKassaWidget(data.confirmationToken, selectedTarif, data.transactionId)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <div>
      <div id="yookassa-container" className={isWidgetOpen ? '' : 'hidden'}></div>
      <div className={`promocode-catalog ${isWidgetOpen ? 'hidden' : ''}`}>
        <div className="promocode-catalog-header">
          <div className="promocode-catalog-header-title">Покупка курса</div>
        </div>
        <ItemGrid
          items ={tariffs}
          selectedItemId={selectedTarifId}
          handleCardClick={handleCardClick}
        />
        <EmailInputContainer
          email={email}
          isEmailValid={isEmailValid}
          selectedItemId={selectedTarifId}
          handleEmailChange={handleEmailChange}
          handlePurchaseClick={handlePurchaseClick}
        />
      </div>
    </div>
  )
}

export default CourseCatalog
