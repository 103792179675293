/*
 * Copyright 2024 LLC Campus.
 */

import React from 'react'

const EmailInputContainer = ({
  email,
  handleEmailChange,
  isEmailValid,
  handlePurchaseClick,
  selectedItemId
}) => {
  return (
    <div className="email-input-container">
      <input
        type="email"
        placeholder="Введите email"
        value={email}
        onChange={handleEmailChange}
        className={`email-input ${!isEmailValid ? 'invalid' : ''}`}
      />
      {!isEmailValid && <div className="error-message">Введите корректный email</div>}

      <div className="promocode-info">
        <p>После оплаты:</p>
        <ul>
          <li>Код активации придет к вам на указанный e-mail.</li>
          <li>Вы можете его подарить другу или активировать подписку для себя в самом приложении: Настройки -
            Подписка Кампус Premium.
          </li>
        </ul>
      </div>


      <button
        onClick={handlePurchaseClick}
        className={`purchase-button ${!selectedItemId ? 'disabled' : ''}`}
        disabled={!selectedItemId || !isEmailValid || email === ''}
      >
        Перейти к оплате
      </button>
      <div className="support-text">
        <p>Возникли проблемы или есть вопросы?</p>
        <p>Если у вас возникли проблемы с оплатой или есть какие-либо вопросы, пожалуйста, свяжитесь с нами через
          один из доступных каналов:</p>
        <ul>
          <li>E-mail: <a href="mailto:dewish.ru@gmail.com">dewish.ru@gmail.com</a></li>
          <li>Чат: в мобильном приложении</li>
        </ul>
        <p>Мы готовы помочь вам в любое время!</p>
      </div>
    </div>
  )
}

export default EmailInputContainer
